/* Add &display=swap: https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools */
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

body {
  margin: 0;
  line-height: 1.6;
  font-family: "Lato";
  background-color: #f85c06d1;
}

#root {
  overflow-x: hidden;
  overflow-y: hidden;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.google-map-label {
  margin-left: 140px;
  color: rgb(139, 28, 28) !important;
}

.react-select-compact .Select-value:first-child {
  font-style: italic;
}
.react-select-compact .Select-value:first-child .Select-value-icon,
.react-select-compact .Select-value:nth-child(n + 2) {
  display: none;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}
[data-title]:hover::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 20px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;	
  border-bottom: 8px solid #000;
}

[data-title] {
  position: relative;
}

.notification-container {
  top: auto;
}
